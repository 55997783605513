import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class SingleCardGiveListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getList', {
      url: '/admin/shopMember/giveList',
      name: 'list',
      label: '列表'
    }],
    
    ['exportList', {
      url: '/admin/shopMember/exportGiveList',
      name: 'export-excel',
      label: '导出'
    }],
   
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
  ])

  // 列表
  public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出列表
  public exportList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}